<template>
	<div class="p-1">
        <div class="flex justify-between mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
            <a-button outlined @click="printDiv">
              Imprimir horario
            </a-button>
        </div>
        <a-card>
            <!-- <template #header> -->
                <div class="flex justify-between">
                    <h3>Ver horarios</h3>
                </div>
                <div class="flex -mx-2">
                    <div class="w-full md:w-2/4 lg:w-1/4 px-2">
                        <a-select placeholder="Buscar por: ..." :source="[
                            { label: 'Aulas', value: 'classroom' },
                            { label: 'Profesores', value: 'teacher' },
                            { label: 'Secciones', value: 'section' },
                        ]" v-model="selection" />
                    </div>
                    <div class="w-full md:w-2/4 lg:w-1/4 px-2">
                        <a-select :disabled="!selection" filterable :source="itemsBySelection" v-model="item" />
                    </div>
                    <div class="w-full md:w-2/4 lg:w-1/4 px-2">
                        <a-period-accessor v-model="queryPeriods.period" class="mr-2" />
                    </div>
                    <a-button v-show="false" v-if="selection === 'classroom'" outlined @click="viewAllSchedules = true" class="w-full md:w-2/4 lg:w-1/4 px-2">
                      Ver Todo
                    </a-button>
                </div>

            <!-- </template> -->

        </a-card>

        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3" id="schedule">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <a-skeleton :count="16" height="30px" v-if="isLoading"/>
                <template v-else>
                    <table class="min-w-full">
                        <thead>
                            <tr>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider">Hora</th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium uppercase tracking-wider" v-for="(day, k) in days" :key="k">
                                    {{ day.label }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(hour, index) in mappedClassHours" :key="hour.value">
                                <td class="px-6 py-4 whitespace-no-wrap bg-white border-b border-gray-200">
                                    {{ hour.label.split('/')[0].trim() | h24 }} / {{ hour.label.split('/')[1].trim() | h24 }}
                                </td>
                                <template v-if="hasAnyToShow()">
                                    <td class="px-6 py-4 whitespaence-no-wrap bg-white border-b border-gray-200 text-center mx-auto text-sm" v-for="(day, k) in days" :key="k"
                                        :style="{background: mappedClassroomsBySchedule(hour, day).background, color: mappedClassroomsBySchedule(hour, day).color}">
                                        {{ mappedClassroomsBySchedule(hour, day).value }}

                                          <template>
                                            <p v-if="mappedClassroomsBySchedule(hour, day).specialization">
                                                Especialidad: {{ mappedClassroomsBySchedule(hour, day).specialization }}
                                            </p>
                                            <p v-if="mappedClassroomsBySchedule(hour, day).semester">
                                                Semestre: {{ mappedClassroomsBySchedule(hour, day).semester }}
                                            </p>
                                            <p v-if="mappedClassroomsBySchedule(hour, day).classroom">
                                                Aula: {{ mappedClassroomsBySchedule(hour, day).classroom }}
                                            </p>
                                            <p v-if="mappedClassroomsBySchedule(hour, day).section">
                                                Sección: {{ mappedClassroomsBySchedule(hour, day).section }}
                                            </p>
                                            <p v-if="mappedClassroomsBySchedule(hour, day).teacher">
                                              Profesor: {{ mappedClassroomsBySchedule(hour, day).teacher }}
                                            </p>
                                        </template>
                                    </td>
                                </template>
                                <template v-else>
                                    <td colspan="5" :rowspan="mappedClassHours.length" class="px-6 py-4 whitespaence-no-wrap bg-white border-b border-gray-200 text-center mx-auto text-xs" style="text-align: center;" v-if="index == 0">
                                        <img src="/no_result.png" alt="no results" class="inline-block">
                                        <h3>No hay resultados encontrados</h3>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
export default {
    data: () => ({
        query: {
            limit: 0,
            search: '',
            with: 'specialization,semester,classroom,period,courseSection,courseSection.section,classHour,courseSection.course'
        },
        queryPeriods: {
            limit: 0,
            sort_by: 'start_hour',
            period: ''
        },
        selection: null,
        item: null,
        viewAllSchedules: false
    }),
    computed: {
        ...mapState({
            Schedules: state => state.schedules.all,
            loading: state => state.schedules.loading,
            classrooms: state => state.classrooms.all,
            teachers: state => state.teachers.all,
            sections: state => state.sections.all,
            classhours: state => state.classhours.all,
            classroomSchedules: state => state.classrooms.schedules,
            sectionSchedules: state => state.sections.schedules,
            teacherSchedules: state => state.teachers.schedules,
            teacherLeading: state => state.teachers.loading,
            classroomsLoading: state => state.classrooms.loading,
            sectionsLoading: state => state.sections.loading,
            loadingPeriods: ({ periods }) => periods.loading
        }),
        ...mapGetters({
            columns: 'schedules/formattedScheduleColumns',
            lang: 'lang'
        }),
        isLoading() {
            return this.teacherLeading || this.classroomsLoading || this.sectionsLoading
        },
        itemsBySelection() {
            if (this.selection) {
                const el = [...this[`${this.selection}s`]]
                return el.sort((a, b) => {
                    if (this.selection === 'section') {
                        return a.semester.number > b.semester.number ? 1 : -1
                    }

                    if (this.selection === 'teacher') {
                        return a.first_name.toUpperCase() > b.first_name.toUpperCase() ? 1 : -1
                    }

                    return 1
                }).map(it => {
                    let label = ''
                    if (this.selection === 'teacher') label = `${it.first_name} ${it.last_name}`
                    if (this.selection === 'section') label = `${it.specialization.title} - Semestre: ${it.semester.number} - Sección: ${it.letter_code}`
                    if (this.selection === 'classroom') label = it.code
                    return { label, value: it.id }
                })
            }

            return []
        },
        mappedClassHours() {
            return this.classhours.map(it => {
                const start_hour = this.$options.filters.timePadding(it.start_hour)
                const start_minute = this.$options.filters.timePadding(it.start_minute)
                const end_hour = this.$options.filters.timePadding(it.end_hour)
                const end_minute = this.$options.filters.timePadding(it.end_minute)
                return {
                    label: `${start_hour}:${start_minute} / ${end_hour}:${end_minute}`,
                    value: it.id
                }
            })
        },
        days() {
            return [
                { label: 'Lunes', value: 1 },
                { label: 'Martes', value: 2 },
                { label: 'Miércoles', value: 3 },
                { label: 'Jueves', value: 4 },
                { label: 'Viernes', value: 5 },
            ]
        },
    },
    watch: {
        'queryPeriods.period'(val) {
            this.fetchClasshours({ ...this.queryPeriods, period: val })
        },
        selection() {
            this.item = null
            this.viewAllSchedules = false
        },
        item(id) {
            this.viewAllSchedules = false
            if (id) {
                this[`fetch${this.$options.filters.capitalize(this.selection)}Schedule`]({
                    id,
                    filters: {
                        limit: 0,
                        period: this.queryPeriods.period,
                        with: `courseSection.course,courseSection.specialization,courseSection.semester,courseSection.section,classroom`
                    }
                })
              console.log(this.sectionSchedules)
            }
        },
        viewAllSchedules (value) {
            if (value) {
                  this.fetchAllSchedule({
                      limit: 0,
                      period: this.queryPeriods.period,
                      with: `courseSection.course,courseSection.specialization,courseSection.semester,courseSection.section,classroom`
                  })
              }
        }
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchClassrooms: 'classrooms/index',
            fetchTeachers: 'teachers/index',
            fetchSections: 'sections/fetchAll',
            fetchClassroomSchedule: 'classrooms/schedules',
            fetchSectionSchedule: 'sections/schedule',
            fetchTeacherSchedule: 'teachers/schedule',
            fetchClasshours: 'classhours/index',
            fetchPeriods: 'periods/index',
            fetchAllSchedule: 'schedules/fetchAll'
        }),
        mappedClassroomsBySchedule(hour, day) {
            let found = this[`${this.selection === 'classroom' && this.viewAllSchedules ? '' : this.selection}Schedules`]?.find(it => it.class_hour_id === hour.value && it.day === day.value)
            if (found) {
                if (found.course_section) {
                    const getTeacher = this.teachers.find($teacher => $teacher.id === found.course_section.teacher_id)
                    return {
                      value: found.course_section.course.title,
                      background: found.course_section.specialization.color,
                      color: found.course_section.specialization.text_color,
                      specialization: found.course_section.specialization.title,
                      semester: found.course_section.semester.number,
                      classroom: found.classroom.description,
                      section: found.course_section.section.letter_code,
                      teacher: getTeacher ? `${getTeacher?.first_name} ${getTeacher?.last_name}` : ''
                    }
                }
            }
            // } else {
            //   console.log('not found')
            // }

            return {}
        },
        hasAnyToShow() {
          return (this[`${this.selection === 'classroom' && this.viewAllSchedules ? '' : this.selection}Schedules`] ?? []).length > 0 && (this.item !== null || this.viewAllSchedules)
        },
        printDiv() {
          // let printContents = document.getElementById('schedule').innerHTML;
          let win = window.open('', 'PRINT', 'height=400,width=600');

          win.document.write('<html><head><title>' + document.title + '</title>');
          win.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.8.10/tailwind.min.css"/>');
          win.document.write('</head><style>body {-webkit-print-color-adjust: exact;padding-top: 15px;}</style><body >');
          // win.document.write('<h1>' + document.title  + '</h1>');
          win.document.write(this.getLogo());
          win.document.write(document.querySelector('#schedule').innerHTML);
          win.document.write('</body></html>');

          win.document.close(); // necessary for IE >= 10
          win.focus(); // necessary for IE >= 10*/

          setTimeout(function () {
            win.print();
          }, 3000)
        },
        getLogo() {
          return `
            <svg xmlns="http://www.w3.org/2000/svg" width="155.409" height="24" viewBox="0 0 155.409 24">
              <g transform="translate(-274.474 -26.391)">
                  <g transform="translate(408.369 28.793)">
                      <path class="a" :fill="color"
                          d="M539.106,52.669V31.138h14.348v2.393h7.165v2.394h-7.165v2.393l-7.174-.009-7.174,7.179,7.2,7.185Z"
                          transform="translate(-539.106 -31.138)" />
                      <path class="a" :fill="color" d="M560.432,63.155l-7.165-7.185,3.58-3.58,3.58,3.583h7.188v7.182Z"
                          transform="translate(-546.102 -41.637)" />
                  </g>
                  <g transform="translate(274.474 26.391)">
                      <g transform="translate(0)">
                          <path class="a" :fill="color" d="M275.4,31.148v9.7h-.924v-9.7Z" transform="translate(-274.474 -28.741)" />
                          <path class="a" :fill="color"
                              d="M281.307,31.148l5.652,8.206h.027V31.148h.924v9.7h-1.033l-5.652-8.207H281.2v8.207h-.924v-9.7Z"
                              transform="translate(-277.339 -28.741)" />
                          <path class="a" :fill="color"
                              d="M299.384,38.7a2.045,2.045,0,0,0,.672.8,2.8,2.8,0,0,0,1.012.428,5.784,5.784,0,0,0,1.25.129,3.513,3.513,0,0,0,.829-.109,2.884,2.884,0,0,0,.835-.346,2.135,2.135,0,0,0,.646-.612,1.55,1.55,0,0,0,.258-.9,1.325,1.325,0,0,0-.183-.708,1.675,1.675,0,0,0-.476-.5,2.81,2.81,0,0,0-.646-.333,6.273,6.273,0,0,0-.693-.21l-2.16-.53a6.435,6.435,0,0,1-.822-.272,2.336,2.336,0,0,1-.7-.435,2.069,2.069,0,0,1-.483-.666,2.278,2.278,0,0,1-.183-.965,3.082,3.082,0,0,1,.136-.829,2.305,2.305,0,0,1,.51-.9,2.968,2.968,0,0,1,1.026-.713,4.108,4.108,0,0,1,1.67-.292,4.887,4.887,0,0,1,1.372.19,3.39,3.39,0,0,1,1.141.571,2.748,2.748,0,0,1,1.074,2.255h-.924a2.248,2.248,0,0,0-.253-.971,2.176,2.176,0,0,0-.584-.693,2.562,2.562,0,0,0-.829-.421,3.36,3.36,0,0,0-.985-.143,4.067,4.067,0,0,0-.917.1,2.31,2.31,0,0,0-.775.326,1.689,1.689,0,0,0-.536.591,1.85,1.85,0,0,0-.2.911,1.4,1.4,0,0,0,.115.591,1.247,1.247,0,0,0,.319.428,1.7,1.7,0,0,0,.469.285,3.524,3.524,0,0,0,.564.176l2.363.584a6.763,6.763,0,0,1,.971.333,3.21,3.21,0,0,1,.8.489,2.069,2.069,0,0,1,.543.713,2.406,2.406,0,0,1,.2,1.019,3.766,3.766,0,0,1-.034.435,2.217,2.217,0,0,1-.163.577,2.9,2.9,0,0,1-.367.618,2.308,2.308,0,0,1-.652.564,3.772,3.772,0,0,1-1.026.408,6.011,6.011,0,0,1-1.467.156,6.122,6.122,0,0,1-1.589-.2,3.416,3.416,0,0,1-1.25-.6,2.66,2.66,0,0,1-.795-1.046,3.426,3.426,0,0,1-.237-1.535h.923a2.723,2.723,0,0,0,.233,1.252Z"
                              transform="translate(-286.204 -28.538)" />
                          <path class="a" :fill="color" d="M321.757,31.148v.788h-3.382v8.912h-.924V31.936h-3.369v-.788Z"
                              transform="translate(-294.041 -28.741)" />
                          <path class="a" :fill="color" d="M332.108,31.148v9.7h-.924v-9.7Z" transform="translate(-302.491 -28.741)" />
                          <path class="a" :fill="color" d="M342.646,31.148v.788h-3.382v8.912h-.924V31.936H334.97v-.788Z"
                              transform="translate(-304.361 -28.741)" />
                          <path class="a" :fill="color"
                              d="M352.674,31.148v6a4.711,4.711,0,0,0,.19,1.433,2.389,2.389,0,0,0,.557.964,2.153,2.153,0,0,0,.9.543,3.905,3.905,0,0,0,1.2.17,3.951,3.951,0,0,0,1.209-.17,2.15,2.15,0,0,0,.9-.543,2.394,2.394,0,0,0,.556-.964,4.711,4.711,0,0,0,.19-1.433v-6h.924v6.209a4.719,4.719,0,0,1-.21,1.42,3.125,3.125,0,0,1-.665,1.175,3.172,3.172,0,0,1-1.169.794,5.236,5.236,0,0,1-3.451,0,3.164,3.164,0,0,1-1.168-.794,3.119,3.119,0,0,1-.666-1.175,4.735,4.735,0,0,1-.21-1.42V31.148Z"
                              transform="translate(-312.65 -28.741)" />
                          <path class="a" :fill="color" d="M375.967,31.148v.788h-3.382v8.912h-.924V31.936h-3.369v-.788Z"
                              transform="translate(-320.823 -28.741)" />
                          <path class="a" :fill="color"
                              d="M384.521,33.885a4.764,4.764,0,0,1,.856-1.609,4.116,4.116,0,0,1,1.433-1.113,5.035,5.035,0,0,1,4,0,4.132,4.132,0,0,1,1.426,1.113,4.764,4.764,0,0,1,.856,1.609,6.573,6.573,0,0,1,0,3.831,4.69,4.69,0,0,1-.856,1.6,4.171,4.171,0,0,1-1.426,1.108,5.035,5.035,0,0,1-4,0,4.154,4.154,0,0,1-1.433-1.108,4.69,4.69,0,0,1-.856-1.6A6.572,6.572,0,0,1,384.521,33.885Zm.849,3.471a4.164,4.164,0,0,0,.652,1.372,3.283,3.283,0,0,0,1.135.971,3.491,3.491,0,0,0,1.657.367,3.456,3.456,0,0,0,1.651-.367,3.3,3.3,0,0,0,1.128-.971,4.173,4.173,0,0,0,.652-1.372,5.877,5.877,0,0,0,0-3.118,4.191,4.191,0,0,0-.652-1.366,3.311,3.311,0,0,0-1.128-.971,3.462,3.462,0,0,0-1.651-.367,3.5,3.5,0,0,0-1.657.367,3.293,3.293,0,0,0-1.135.971,4.181,4.181,0,0,0-.652,1.366A5.878,5.878,0,0,0,385.37,37.356Z"
                              transform="translate(-328.7 -28.544)" />
                          <path class="a" :fill="color"
                              d="M408.069,31.148a5.014,5.014,0,0,1,1.461.225,3.359,3.359,0,0,1,1.331.774,4.038,4.038,0,0,1,.965,1.488A6.439,6.439,0,0,1,412.2,36a5.846,5.846,0,0,1-.38,2.226,3.953,3.953,0,0,1-2.472,2.351,5.336,5.336,0,0,1-1.7.272h-2.975v-9.7Zm-.354,9.429a4.722,4.722,0,0,0,1.624-.279,3.71,3.71,0,0,0,1.345-.842,3.937,3.937,0,0,0,.911-1.427A5.623,5.623,0,0,0,411.927,36a6.322,6.322,0,0,0-.34-2.242,3.722,3.722,0,0,0-.9-1.406,3.076,3.076,0,0,0-1.256-.727,5.11,5.11,0,0,0-1.433-.2h-3.056v9.158Z"
                              transform="translate(-338.796 -28.741)" />
                          <path class="a" :fill="color" d="M428.566,40.577v.272h-6.494v-9.7h6.44v.272h-6.169v4.239h5.8v.272h-5.8v4.646Z"
                              transform="translate(-347.392 -28.741)" />
                          <path class="a" :fill="color"
                              d="M440.329,31.148a4.534,4.534,0,0,1,3.335,1.243A5.092,5.092,0,0,1,444.785,36a5.091,5.091,0,0,1-1.121,3.607,4.536,4.536,0,0,1-3.335,1.243h-3.356v-9.7Zm-.462,8.912a6.35,6.35,0,0,0,1.8-.224,2.879,2.879,0,0,0,1.243-.713,2.945,2.945,0,0,0,.72-1.265,7.622,7.622,0,0,0,0-3.722,2.94,2.94,0,0,0-.72-1.265,2.884,2.884,0,0,0-1.243-.713,6.37,6.37,0,0,0-1.8-.224H437.9V40.06Z"
                              transform="translate(-354.754 -28.741)" />
                          <path class="a" :fill="color" d="M456.424,31.148v9.7H455.5v-9.7Z" transform="translate(-363.907 -28.741)" />
                          <path class="a" :fill="color"
                              d="M461.5,38.7a2.051,2.051,0,0,0,.673.8,2.8,2.8,0,0,0,1.012.428,5.781,5.781,0,0,0,1.25.129,3.516,3.516,0,0,0,.829-.109,2.892,2.892,0,0,0,.835-.346,2.133,2.133,0,0,0,.645-.612,1.549,1.549,0,0,0,.259-.9,1.326,1.326,0,0,0-.184-.708,1.675,1.675,0,0,0-.476-.5,2.815,2.815,0,0,0-.645-.333,6.286,6.286,0,0,0-.693-.21l-2.16-.528a6.4,6.4,0,0,1-.822-.272,2.33,2.33,0,0,1-.7-.435,2.06,2.06,0,0,1-.482-.666,2.279,2.279,0,0,1-.184-.965,3.084,3.084,0,0,1,.136-.829,2.305,2.305,0,0,1,.509-.9,2.974,2.974,0,0,1,1.026-.713,4.108,4.108,0,0,1,1.67-.292,4.886,4.886,0,0,1,1.372.19,3.393,3.393,0,0,1,1.141.571,2.749,2.749,0,0,1,1.073,2.255h-.924a2.25,2.25,0,0,0-.253-.971,2.173,2.173,0,0,0-.584-.693,2.573,2.573,0,0,0-.829-.421,3.364,3.364,0,0,0-.985-.143,4.071,4.071,0,0,0-.917.1,2.31,2.31,0,0,0-.775.326,1.688,1.688,0,0,0-.536.591,1.849,1.849,0,0,0-.2.911,1.4,1.4,0,0,0,.115.591,1.253,1.253,0,0,0,.319.428,1.712,1.712,0,0,0,.469.285,3.524,3.524,0,0,0,.564.176l2.364.584a6.8,6.8,0,0,1,.972.333,3.215,3.215,0,0,1,.8.489,2.063,2.063,0,0,1,.543.713,2.4,2.4,0,0,1,.2,1.019,3.647,3.647,0,0,1-.034.435,2.2,2.2,0,0,1-.163.577,2.885,2.885,0,0,1-.367.618,2.312,2.312,0,0,1-.652.564,3.769,3.769,0,0,1-1.026.408,6.012,6.012,0,0,1-1.467.156,6.122,6.122,0,0,1-1.589-.2,3.422,3.422,0,0,1-1.25-.6,2.66,2.66,0,0,1-.794-1.046,3.426,3.426,0,0,1-.238-1.535h.924A2.723,2.723,0,0,0,461.5,38.7Z"
                              transform="translate(-366.297 -28.54)" />
                          <path class="a" :fill="color" d="M484.939,31.148v.788h-5.774v3.5h5.407v.788h-5.407V40.06h5.841v.788h-6.766v-9.7Z"
                              transform="translate(-375.141 -28.741)" />
                          <path class="a" :fill="color"
                              d="M495.17,28.8,500.821,37h.027V28.8h.924v9.7h-1.033l-5.651-8.207h-.027V38.5h-.924V28.8Zm4.789-1.942a1.6,1.6,0,0,1-.231.414,1.167,1.167,0,0,1-.347.3.947.947,0,0,1-.475.115,1.525,1.525,0,0,1-.53-.1,3.973,3.973,0,0,1-.489-.217q-.231-.122-.435-.217a.9.9,0,0,0-.381-.1.759.759,0,0,0-.516.177.645.645,0,0,0-.2.516h-.53a1.763,1.763,0,0,1,.122-.476,1.638,1.638,0,0,1,.244-.414,1.193,1.193,0,0,1,.367-.3,1,1,0,0,1,.475-.115,1.552,1.552,0,0,1,.543.1q.258.1.482.2l.421.2a.885.885,0,0,0,.387.095.569.569,0,0,0,.469-.19,1.31,1.31,0,0,0,.238-.462h.516A2.55,2.55,0,0,1,499.958,26.855Z"
                              transform="translate(-382.995 -26.391)" />
                          <path class="a" :fill="color"
                              d="M512.331,33.884a4.782,4.782,0,0,1,.856-1.609,4.117,4.117,0,0,1,1.433-1.113,4.579,4.579,0,0,1,2-.414,4.532,4.532,0,0,1,2,.414,4.14,4.14,0,0,1,1.427,1.113,4.769,4.769,0,0,1,.856,1.609,6.572,6.572,0,0,1,0,3.831,4.7,4.7,0,0,1-.856,1.6,4.179,4.179,0,0,1-1.427,1.108,4.539,4.539,0,0,1-2,.414,4.586,4.586,0,0,1-2-.414,4.154,4.154,0,0,1-1.433-1.108,4.706,4.706,0,0,1-.856-1.6A6.573,6.573,0,0,1,512.331,33.884Zm.849,3.471a4.172,4.172,0,0,0,.652,1.372,3.281,3.281,0,0,0,1.134.971,3.494,3.494,0,0,0,1.657.367,3.453,3.453,0,0,0,1.651-.367,3.3,3.3,0,0,0,1.128-.971,4.18,4.18,0,0,0,.652-1.372,5.877,5.877,0,0,0,0-3.118,4.2,4.2,0,0,0-.652-1.366,3.31,3.31,0,0,0-1.128-.971,3.459,3.459,0,0,0-1.651-.367,3.5,3.5,0,0,0-1.657.367,3.291,3.291,0,0,0-1.134.971,4.19,4.19,0,0,0-.652,1.366,5.877,5.877,0,0,0,0,3.118Z"
                              transform="translate(-391.842 -28.542)" />
                          <path class="a" :fill="color"
                              d="M380.684,54.265a5.014,5.014,0,0,1,1.46.225,3.359,3.359,0,0,1,1.331.774,4.037,4.037,0,0,1,.965,1.488,6.441,6.441,0,0,1,.374,2.364,5.852,5.852,0,0,1-.381,2.226,3.953,3.953,0,0,1-2.472,2.35,5.336,5.336,0,0,1-1.7.272h-2.975v-9.7Zm-.354,9.429a4.719,4.719,0,0,0,1.623-.279,3.712,3.712,0,0,0,1.345-.842,3.952,3.952,0,0,0,.911-1.427,5.635,5.635,0,0,0,.333-2.031,6.307,6.307,0,0,0-.34-2.242,3.721,3.721,0,0,0-.9-1.406,3.08,3.08,0,0,0-1.256-.727,5.115,5.115,0,0,0-1.433-.2h-3.057v9.155Z"
                              transform="translate(-325.268 -40.162)" />
                          <path class="a" :fill="color" d="M401.179,63.694v.272h-6.494v-9.7h6.439v.271h-6.168v4.24h5.8v.272h-5.8v4.646Z"
                              transform="translate(-333.862 -40.162)" />
                          <path class="a" :fill="color"
                              d="M415.83,56.008a2.724,2.724,0,0,0-.679-.74,3.045,3.045,0,0,0-.883-.455,3.277,3.277,0,0,0-1.012-.156,3.5,3.5,0,0,0-1.658.367,3.293,3.293,0,0,0-1.135.971,4.19,4.19,0,0,0-.652,1.366,5.878,5.878,0,0,0,0,3.118,4.174,4.174,0,0,0,.652,1.372,3.283,3.283,0,0,0,1.135.971,3.492,3.492,0,0,0,1.658.367,2.95,2.95,0,0,0,1.222-.244,2.9,2.9,0,0,0,.938-.666,3.4,3.4,0,0,0,.638-.985,4.08,4.08,0,0,0,.313-1.214h.925a5.239,5.239,0,0,1-.421,1.619,3.978,3.978,0,0,1-.843,1.222,3.589,3.589,0,0,1-1.214.775,4.277,4.277,0,0,1-1.555.272,4.578,4.578,0,0,1-2-.414,4.154,4.154,0,0,1-1.433-1.108,4.679,4.679,0,0,1-.856-1.6,6.572,6.572,0,0,1,0-3.831,4.764,4.764,0,0,1,.856-1.61,4.116,4.116,0,0,1,1.433-1.113,4.858,4.858,0,0,1,3.356-.211,3.934,3.934,0,0,1,1.2.6,3.535,3.535,0,0,1,.9.985,3.317,3.317,0,0,1,.455,1.345h-.928A3.112,3.112,0,0,0,415.83,56.008Z"
                              transform="translate(-340.776 -39.969)" />
                          <path class="a" :fill="color"
                              d="M431.327,54.265l3.8,9.7h-.992l-1.182-3.016h-4.415l-1.168,3.016H426.4l3.9-9.7Zm1.316,5.9-1.875-4.972-1.943,4.972Z"
                              transform="translate(-349.528 -40.162)" />
                          <path class="a" :fill="color"
                              d="M449.845,54.265a4.213,4.213,0,0,1,1.134.152,2.737,2.737,0,0,1,.937.455,2.2,2.2,0,0,1,.639.788,2.546,2.546,0,0,1,.237,1.134,2.722,2.722,0,0,1-.476,1.6,2.168,2.168,0,0,1-1.413.87v.024a2.149,2.149,0,0,1,.788.253,1.591,1.591,0,0,1,.51.469,1.942,1.942,0,0,1,.285.658,4.76,4.76,0,0,1,.115.81c.009.163.018.363.027.6s.027.474.055.713a4.339,4.339,0,0,0,.129.68,1.291,1.291,0,0,0,.238.5h-1.02a1.2,1.2,0,0,1-.129-.333,2.984,2.984,0,0,1-.068-.408q-.021-.21-.033-.414c-.01-.136-.019-.253-.028-.354q-.027-.516-.088-1.026a2.823,2.823,0,0,0-.258-.911,1.6,1.6,0,0,0-.571-.645,1.72,1.72,0,0,0-1.026-.218h-3.5v4.307H445.4v-9.7Zm-.068,4.6a2.46,2.46,0,0,0,.8-.129,2.007,2.007,0,0,0,.666-.373,1.839,1.839,0,0,0,.455-.591,1.787,1.787,0,0,0,.17-.794,1.91,1.91,0,0,0-.517-1.413,2.144,2.144,0,0,0-1.575-.516h-3.451v3.817Z"
                              transform="translate(-358.917 -40.162)" />
                          <path class="a" :fill="color"
                              d="M466.151,54.265l3.8,9.7h-.992l-1.182-3.016h-4.415L462.2,63.965h-.978l3.9-9.7Zm1.315,5.9-1.875-4.972-1.943,4.972Z"
                              transform="translate(-366.732 -40.162)" />
                          <path class="a" :fill="color"
                              d="M486.471,56.008a2.712,2.712,0,0,0-.679-.74,3.042,3.042,0,0,0-.883-.455,3.28,3.28,0,0,0-1.012-.156,3.5,3.5,0,0,0-1.657.367,3.289,3.289,0,0,0-1.135.971,4.191,4.191,0,0,0-.652,1.366,5.866,5.866,0,0,0,0,3.118,4.174,4.174,0,0,0,.652,1.372,3.279,3.279,0,0,0,1.135.971,3.491,3.491,0,0,0,1.657.367,2.95,2.95,0,0,0,1.223-.244,2.9,2.9,0,0,0,.937-.666,3.412,3.412,0,0,0,.639-.985,4.08,4.08,0,0,0,.313-1.214h.924a5.225,5.225,0,0,1-.422,1.619,3.967,3.967,0,0,1-.842,1.222,3.59,3.59,0,0,1-1.214.775,4.279,4.279,0,0,1-1.556.272,4.577,4.577,0,0,1-2-.414,4.16,4.16,0,0,1-1.433-1.108,4.689,4.689,0,0,1-.856-1.6,6.573,6.573,0,0,1,0-3.831,4.772,4.772,0,0,1,.856-1.609,4.123,4.123,0,0,1,1.433-1.113,4.857,4.857,0,0,1,3.356-.211,3.94,3.94,0,0,1,1.194.6,3.543,3.543,0,0,1,.9.985,3.314,3.314,0,0,1,.455,1.345h-.923a3.1,3.1,0,0,0-.4-1Z"
                              transform="translate(-375.675 -39.969)" />
                          <path class="a" :fill="color"
                              d="M501.968,54.265l3.8,9.7h-.992L503.6,60.949h-4.416l-1.168,3.016h-.978l3.9-9.7Zm1.316,5.9-1.872-4.972-1.943,4.972Z"
                              transform="translate(-384.426 -40.162)" />
                          <path class="a" :fill="color"
                              d="M516.226,61.823a2.048,2.048,0,0,0,.673.8,2.791,2.791,0,0,0,1.012.428,5.779,5.779,0,0,0,1.25.129,3.542,3.542,0,0,0,.829-.108,2.9,2.9,0,0,0,.835-.347,2.123,2.123,0,0,0,.645-.612,1.549,1.549,0,0,0,.259-.9,1.321,1.321,0,0,0-.184-.708,1.678,1.678,0,0,0-.476-.5,2.819,2.819,0,0,0-.645-.333,6.313,6.313,0,0,0-.693-.21l-2.161-.53a6.433,6.433,0,0,1-.822-.272,2.338,2.338,0,0,1-.7-.435,2.058,2.058,0,0,1-.482-.666,2.276,2.276,0,0,1-.183-.964,3.075,3.075,0,0,1,.135-.829,2.3,2.3,0,0,1,.51-.9,2.967,2.967,0,0,1,1.025-.713,4.108,4.108,0,0,1,1.67-.292,4.883,4.883,0,0,1,1.372.19,3.39,3.39,0,0,1,1.142.571,2.746,2.746,0,0,1,1.073,2.255h-.923a2.256,2.256,0,0,0-.253-.971,2.173,2.173,0,0,0-.584-.693,2.573,2.573,0,0,0-.829-.421,3.363,3.363,0,0,0-.985-.143,4.07,4.07,0,0,0-.917.1,2.305,2.305,0,0,0-.774.326,1.681,1.681,0,0,0-.537.591,1.85,1.85,0,0,0-.2.911,1.4,1.4,0,0,0,.116.591,1.246,1.246,0,0,0,.319.428,1.711,1.711,0,0,0,.469.285,3.54,3.54,0,0,0,.564.176l2.364.584a6.786,6.786,0,0,1,.972.333,3.218,3.218,0,0,1,.8.489,2.062,2.062,0,0,1,.543.713,2.4,2.4,0,0,1,.2,1.019,3.672,3.672,0,0,1-.034.435,2.2,2.2,0,0,1-.163.577,2.881,2.881,0,0,1-.367.618,2.305,2.305,0,0,1-.651.565,3.767,3.767,0,0,1-1.026.408,6.012,6.012,0,0,1-1.467.156,6.121,6.121,0,0,1-1.589-.2,3.421,3.421,0,0,1-1.25-.6,2.659,2.659,0,0,1-.794-1.046,3.427,3.427,0,0,1-.238-1.535H516A2.731,2.731,0,0,0,516.226,61.823Z"
                              transform="translate(-393.332 -39.961)" />
                      </g>
                  </g>
              </g>
            </svg>
          `
        }
    },
    mounted() {
        this.fetchPeriods({ limit: 0, sort_by: 'year,asc' })
          .finally(() => {
            this.fetchClasshours({ limit: 0, period: this.queryPeriods.period })
            this.fetchClassrooms({ limit: 0, enabled: true })
            this.fetchTeachers({ limit: 0, enabled: true })
            this.fetchAllSchedule({ limit: 0 })
            this.fetchSections({ limit: 0, with: 'period,semester,specialization', period: this.queryPeriods.period, enabled: true })
          })
        this.setNavbarTitle(['Listado de horarios'])
    }
}
</script>
