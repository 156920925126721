<template>
    <div class="flex flex-wrap -mx-2">
        <div class="p-2 w-full lg:w-1/4 md:w-1/3" v-for="(field, key) in filterFields" :key="key">
            <label>{{ field.label }}</label>
            <a-select
                :source="field.source"
                v-model="selectionFilters[field.binding]"
                :loading="field.loading"
                :disabled="field.disabled"
                @input="handleFieldChange(field, $event)"
            />
        </div>
    </div>
</template>

<script>
//TODO Está dando un error al cambiar la especialidad
import {mapActions, mapState} from 'vuex'
export default {
    props: {
        routeUpdate: { required: false, type: Boolean, default: false },
        value: { required: false, type: Object, default: () => ({}) }
    },
    data: () => ({
        selectionFilters: {
            specialization_id: null,
            semester_id: null,
            section_id: null,
            course_id: null
        },
        loading: false,
        period: {}
    }),
    methods: {
        ...mapActions({
          fetchSectionSchedule: 'sections/schedule'
        }),
        handleFieldChange(field, $event) {
            this.$emit(`on-${field.binding}`, $event)

            if (this.routeUpdate) {
                this.$router.replace({ query: { [field.binding]: $event } })
                  .catch(() => null)
            }

            field.clears.forEach($0 => this.selectionFilters[$0] = null)
        },
    },
    computed: {
        ...mapState({
            user: ({ session }) => session.user,
            sectionSchedules: state => state.sections.schedules,
        }),
        pensum() {
            return this.period?.pensums?.find?.($0 => $0.specialization_id === this.selectionFilters.specialization_id)
        },
        specialization() {
            return this.pensum?.specialization
        },
        semester() {
            return this.pensum?.semesters?.find?.($0 => $0.id === this.selectionFilters.semester_id)
        },
        section() {
            return this.semester?.sections?.find?.($0 => $0.id === this.selectionFilters.section_id)
        },
        mappedSpecializations() {
            return this.period?.pensums
                ?.map($0 => ({ label: `${$0.specialization.code} - ${$0.specialization.title}`, value: $0.specialization.id }))
                ?.uniqueBy(($0, $1) => $0.value === $1.value)
                .sort((a, b) => a.label > b.label ? 1 : -1) ?? []
        },
        mappedSemesters() {
            return this.pensum?.semesters
                ?.filter($0 => $0.sections.length > 0)
                ?.map($0 => ({ label: $0.number, value: $0.id })) ?? []
        },
        mappedSections() {
            return this.semester?.sections
                ?.filter($0 => $0.period_id === this.period?.id)
                ?.filter($0 => $0.courses.length > 0)
                ?.map($0 => ({ label: $0.letter_code, value: $0.id }))
                ?.sort((a, b) => a.label > b.label ? 1 : -1) ?? []
        },
        mappedCourses() {
            return this.section?.courses
                ?.map($0 => ({ label: $0.title, value: $0.id }))
        },
        filterFields() {
            const specialization = {
                label: 'Especialidad',
                source: this.mappedSpecializations,
                binding: 'specialization_id',
                loading: this.loading,
                disabled: this.loading,
                clears: ['semester_id', 'section_id', 'course_id']
            }

            const semester = {
                label: 'Semestre',
                source: this.mappedSemesters,
                binding: 'semester_id',
                loading: false,
                disabled: !this.selectionFilters.specialization_id || this.loading,
                clears: ['section_id', 'course_id']
            }

            const section = {
                label: 'Sección',
                source: this.mappedSections,
                binding: 'section_id',
                loading: false,
                disabled: !this.selectionFilters.semester_id || this.loading,
                clears: ['course_id']
            }

            const course =  {
                label: 'Materia',
                source: this.mappedCourses,
                binding: 'course_id',
                loading: false,
                disabled: !this.selectionFilters.section_id || this.loading,
                clears: []
            }

            return [specialization, semester, section, course]
        },
        computedCourseSection() {
            return `${this.selectionFilters.course_id}|${this.selectionFilters.section_id}`
        }
    },
    watch: {
        computedCourseSection(val) {
            const [course_id, section_id] = val.split('|')
            const course = parseInt(course_id)
            const section = parseInt(section_id)
            this.fetchSectionSchedule({
              id: section,
              filters: {
                limit: 0,
                with: `courseSection.course,courseSection.specialization,courseSection.semester,courseSection.section,classroom`
              }
            })
            if (course && section) {

                this.loading = !this.loading
                this.$emit('loading', true)
                this.$repository.courseSections
                    .show(section, course, { with: 'teacher' })
                    .then(res => {
                        this.$emit('input', res.data);
                    })
                    .catch(() => {
                        this.$emit('input', null);
                    })
                    .finally(() => {
                        this.loading = !this.loading
                        this.$emit('loading', false)
                    })
            }
        },
        value(val) {
            if (val) {
                this.selectionFilters = {
                    ...this.selectionFilters,
                    ...val
                }
            }
        }
    },
    mounted() {
        this.loading = !this.loading
            this.$repository.periods.enabled({
                with: 'pensums.specialization,pensums.semesters.sections.courses'
            }).then(({ data }) => {
                this.period = data
            })
            .finally(() => {
                this.loading = !this.loading
                if (this.$route.query.specialization || this.$route.query.semester || this.$route.query.section || this.$route.query.course) {
                  this.selectionFilters = {
                    specialization_id: parseInt(this.$route.query.specialization),
                    semester_id: parseInt(this.$route.query.semester),
                    section_id: this.$route.query.section,
                    course_id: parseInt(this.$route.query.course)
                  }
                }
            })
    }
}
</script>
