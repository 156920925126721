<template>
    <div>
        <!--  PRESENTATIONAL AND EDIT TABLE  -->
        <div class="-my-2 py-2">
            <div class="align-middle inline-block min-w-full rounded-2xl border-gray-200 bg-white">
                <div class="flex justify-between items-center p-2">
                    <h2 class="text-xl px-2">Horario</h2>
                    <a-select
                        class="w-52"
                        placeholder="Aula"
                        :loading="loadings.general"
                        :source="mappedClassrooms"
                        v-model="bulk.classroom_id"/>
                </div>
                <div class="text-center w-full mb-2">
                    <small class="text-gray-400">Para <span class="text-gray-500">actualizar o crear</span> un horario por favor seleccione un aula de clases</small>
                </div>
                <!-- FILLED TABLE (Existing and unexisting schedules) -->
                <table class="min-w-full" v-if="!isCreating">
                    <thead>
                    <tr>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider">Hora</th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium uppercase tracking-wider" v-for="(day, k) in days" :key="k">
                            {{ day.label }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <!-- LOADING TABLE -->
                        <template v-if="loadings.general">
                            <tr v-for="n in 8" :key="n">
                                <td v-for="t in 6" :key="`${n}-${t}`" class="px-6 py-4 bg-white whitespaence-no-wrap border-b border-gray-200 text-center mx-auto">
                                    <a-skeleton class="h-14 w-full" />
                                </td>
                            </tr>
                        </template>
                        <template v-else-if="!loadings.general">
                            <template v-if="mappedClassHours.length">
                                <tr v-for="(hour, index) in mappedClassHours" :key="hour.value">
                                    <td class="px-6 bg-white py-4 border-b border-gray-200 border-r text-center">
<!--                                        {{ hour.label.split('/')[0].trim() | h24 }} / {{ hour.label.split('/')[1].trim() | h24 }}-->
                                        {{ hour.label.split('/')[0] | h24 }}
                                    </td>
                                    <template v-if="schedules.length > 0">
                                        <td
                                            class="px-6 py-4 bg-white whitespaence-no-wrap border-b border-gray-200 text-center mx-auto"
                                            :class="[(isClasshourUnavailable(day.value, hour.value) && isCreating) && 'bg-gray-300']"
                                            :style="{
                                                background: getClassroomWithSpecializtion(hour, day).specialization.background,
                                                color: getClassroomWithSpecializtion(hour, day).specialization.text_color,
                                            }"
                                            v-for="(day, k) in days" :key="k">
                                            <template v-if="!loadings.schedules">
                                                <a-dropdown v-if="getClassroomWithSpecializtion(hour, day).classroom">
                                                    <b class="ml-2" @click="clearChanges" v-if="getClassroomWithSpecializtion(hour, day).classroom">
                                                        {{ getClassroomWithSpecializtion(hour, day).classroom.description }}
                                                    </b>
                                                    <template #menu>
                                                        <template v-if="bulk.classroom_id">
                                                            <a-dropdown-item @click="select('classhour', getClassroomWithSpecializtion(hour, day))" class="text-gray-700">
                                                                Cambiar hora de clase
                                                            </a-dropdown-item>
                                                            <a-dropdown-item @click="select('classroom', getClassroomWithSpecializtion(hour, day))" class="text-gray-700">
                                                                Cambiar aula
                                                            </a-dropdown-item>
                                                            <a-dropdown-item @click="select('day', getClassroomWithSpecializtion(hour, day))" class="text-gray-700">
                                                                Cambiar día
                                                            </a-dropdown-item>
                                                            <a-dropdown-item @click="deleteSchedule(getClassroomWithSpecializtion(hour, day).schedule)" class="text-gray-700">
                                                                <div class="flex">
                                                                    <a-loader class="mr-2" v-if="deleting.includes(getClassroomWithSpecializtion(hour, day).schedule.id)" /> Eliminar
                                                                </div>
                                                            </a-dropdown-item>
                                                        </template>
                                                        <template v-else>
                                                            <span class="text-black p-1 inline-block">
                                                                Sin opciones disponibles
                                                            </span>
                                                        </template>
                                                    </template>
                                                </a-dropdown>
                                                <template v-else-if="bulk.classroom_id">
                                                    <template v-if="!isClasshourUnavailable(day.value, hour.value)">
<!--                                                        <div class="sim-checkbox mx-auto" style="width: 20px; height: 20px;">-->
<!--                                                            <label :for="`course_input_${k}_${index}`">-->
<!--                                                                <div class="sim-checkbox-square" :style="{ border: `2px solid #006CFF`, color: `#006CFF`, opacity: isDaySelectedForClassHour(k, hour.value) ? 1 : 0.5 }">-->
<!--                                                                    <transition name="scale">-->
<!--                                                                        <slot name="icon">-->
<!--                                                                            <a-icon-->
<!--                                                                              name="check"-->
<!--                                                                              v-if="isDaySelectedForClassHour(k, hour.value) || isClasshourUnavailable(day.value, hour.value)"-->
<!--                                                                              :color="isDaySelectedForClassHour(k, hour.value) ? `#006CFF` : '#000'"/>-->
<!--                                                                        </slot>-->
<!--                                                                    </transition>-->
<!--                                                                </div>-->
<!--                                                            </label>-->
<!--                                                        </div>-->
                                                        <input
                                                          type="checkbox"
                                                          style="display: none;"
                                                          :disabled="isClasshourUnavailable(day.value, hour.value) || loadings.general"
                                                          :id="`course_input_${k}_${index}`"
                                                          :value="hour.value" v-model="bulk.schedules[k].class_hours_ids">
                                                        <input
                                                          type="checkbox"
                                                          class="appearance-none cursor-pointer border checked:ring-2 checked:ring-primary w-4 h-4 checked:border-transparent ring-offset-2 border rounded border-gray-200 active:scale-95 transition-all transform checked:bg-primary"
                                                          :value="hour.value" v-model="bulk.schedules[k].class_hours_ids"/>
                                                    </template>
                                                    <template v-else>
                                                        <span v-tooltip="`Por ${getTakenCourse(day.value, hour.value)}`">Ocupado</span>
                                                    </template>
                                                </template>
                                                <template v-else></template>
                                            </template>
                                            <template v-else>
                                                <a-skeleton class="h-6 w-full" />
                                            </template>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td :colspan="days.length + 1" :rowspan="mappedClassHours.length" v-if="index === 0">
                                            <div class="flex flex-col w-full">
                                                <p class="text-2xl p-8 text-center">Parece que esta materia no tiene un horario asignado</p>
                                                <div class="text-center">
                                                    <a-button class="mt-2" outlined @click="isCreating = true">Crear horario</a-button>
                                                </div>
                                            </div>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td :colspan="days.length + 1" class="px-6 bg-white py-4 border-b border-gray-200 text-center">
                                        <div class="flex flex-col w-full">
                                            <p class="text-2xl p-8 text-center">Parece que no hay horas de clase registradas para el periodo actual</p>
                                            <div class="text-center">
                                                <a-button outlined :to="{ name: 'create_classhour' }">Crear hora de clase</a-button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>

                <!-- Creating schedule table -->
                <template v-else>
                    <table class="min-w-full" v-if="bulk.classroom_id">
                        <thead>
                            <tr>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider">Hora</th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium uppercase tracking-wider" v-for="(day, k) in days" :key="k">
                                    {{ day.label }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(hour, t) in mappedClassHours" :key="hour.value">
                                <td class="px-6 py-4 bg-white border-b border-r text-center border-gray-200">
<!--                                    {{ hour.label.split('/')[0] }} / {{ hour.label.split('/')[1].trim() | h24 }}-->
                                    {{ hour.label.split('/')[0] | h24 }}
                                </td>
                                <td
                                    class="px-6 py-4 bg-white whitespaence-no-wrap border-b border-gray-200 text-center mx-auto"
                                    :class="[isClasshourUnavailable(day.value, hour.value) && 'bg-gray-300']"
                                    v-for="(day, k) in days" :key="k">
                                    <template v-if="!loadings.schedules">
                                      <template v-if="!isClasshourUnavailable(day.value, hour.value)">
                                        <!--                                        <div class="sim-checkbox mx-auto" style="width: 20px; height: 20px;" v-if="!isClasshourUnavailable(day.value, hour.value)">-->
                                        <!--                                            <label :for="`course_input_${k}_${t}`">-->

                                        <!--                                                <div class="sim-checkbox-square" :style="{ border: `2px solid #006CFF`, color: `#006CFF`, opacity: isDaySelectedForClassHour(k, hour.value) ? 1 : 0.5 }">-->
                                        <!--                                                    <transition name="scale">-->
                                        <!--                                                        <slot name="icon">-->
                                        <!--                                                            <a-icon name="check" v-if="isDaySelectedForClassHour(k, hour.value) || isClasshourUnavailable(day.value, hour.value)" :color="isDaySelectedForClassHour(k, hour.value) ? `#006CFF` : '#000'"/>-->
                                        <!--                                                        </slot>-->
                                        <!--                                                    </transition>-->
                                        <!--                                                </div>-->

                                        <!--                                            </label>-->
                                        <!--                                        </div>-->
                                        <!--                                        <input type="checkbox" style="display: none" :disabled="isClasshourUnavailable(day.value, hour.value) || loadings.general" :id="`course_input_${k}_${t}`" :value="hour.value" v-model="bulk.schedules[k].class_hours_ids">-->
                                        <input
                                          type="checkbox"
                                          style="display: none;"
                                          :disabled="isClasshourUnavailable(day.value, hour.value) || loadings.general"
                                          :id="`course_input_${k}_${t}`"
                                          :value="hour.value" v-model="bulk.schedules[k].class_hours_ids">
                                        <input
                                          type="checkbox"
                                          class="appearance-none cursor-pointer border checked:ring-2 checked:ring-primary w-4 h-4 checked:border-transparent ring-offset-2 border rounded border-gray-200 active:scale-95 transition-all transform checked:bg-primary"
                                          :value="hour.value" v-model="bulk.schedules[k].class_hours_ids"/>
                                      </template>
                                    </template>
                                    <template v-else>
                                        <a-skeleton class="h-14 w-full" />
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>

            <div class="flex justify-end mt-2 space-x-2" v-if="isCreating || (bulk.classroom_id && bulk.schedules.some($0 => $0.class_hours_ids.length))">
                <a-button @click="handleStoreSchedule" :disabled="!bulk.classroom_id">
                    Guardar horario
                </a-button>

                <a-button outlined @click="isCreating = false">
                    Cancelar
                </a-button>
            </div>
        </div>

        <a-modal
            :show="selected.type === 'classhour'"
            @cancel="cancel"
            :disabled-confirm="!classhour_id"
            @confirm="updateSchedule({ class_hour_id: classhour_id })">
            <a-select
                label="Hora"
                placeholder="Hora"
                :source="filteredMappedClassHours"
                v-model="classhour_id"
                class="mt-2 text-left"/>
        </a-modal>

        <a-modal
            :show="selected.type === 'classroom'"
            @cancel="cancel"
            :disabled-confirm="!classroom_id"
            @confirm="updateSchedule({ classroom_id })">
            <a-select
                label="Aula"
                placeholder="Aula"
                :source="mappedClassrooms"
                v-model="classroom_id"
                class="mt-2 text-left"/>
        </a-modal>

        <a-modal
            :show="selected.type === 'day'"
            @cancel="cancel"
            :disabled-confirm="!day"
            @confirm="updateSchedule({ day })">
            <a-select
                label="Día"
                placeholder="Día"
                :source="days"
                v-model="day"
                class="mt-2 text-left"/>
        </a-modal>
    </div>
</template>

<script>
/**
 * TODO Al momento de realizar la consulta del semestre/seccion del select. no está agarrando el periodo actual. ruta: "schedules/show"
 */
const base_bulk = {
    classroom_id: null,
    schedules: [
        { "day": 1, "class_hours_ids": [] },
        { "day": 2, "class_hours_ids": [] },
        { "day": 3, "class_hours_ids": [] },
        { "day": 4, "class_hours_ids": [] },
        { "day": 5, "class_hours_ids": [] },
    ]
}
import { mapState, mapActions } from 'vuex';
import hourTransform from "@/filters/hourTransform";
export default {
    props: {
        courseSection: { required: true, type: Object },
    },
    data: () => ({
        schedules: [],
        classhours: [],
        classrooms: [],
        takenClasshours: [],
        schedule: {},
        loadings: {
            general: false,
            classhours: false,
            classrooms: false,
            schedules: false
        },
        selected: {
            item: null,
            type: null
        },
        classroom_id: null,
        classhour_id: null,
        day: null,
        bulk: {...base_bulk},
        isCreating: false,
        deleting: []
    }),
    methods: {
        cancel() {
            this.selected = { type: null, item: null }
            this.classroom_id = null
            this.classhour_id = null
            this.day = null
        },
        getTakenCourse(day, classhour_id) {
            const taken = this.findClasshourByDay(day)
            // this.isClasshourUnavailable(day)
            let title = undefined
            if (classhour_id) {
              title = taken.extra.find($0 => {
                return $0.class_hour_id === classhour_id
              })
            }
            // console.log(title)

            // if (title) {
            //     const classroom = this.classrooms.find($0 => $0.id === taken.classroom_id)
            //     if (classroom) {
            //         title = `${title} en el salón ${classroom.code}`
            //     }
            // }

            if (title) {
              return title?.course_section?.course?.title
            } else {
              return 'No encontrado'
            }
        },
        select(type, item) {
            this.selected = { type, item }
        },
        deleteSchedule(schedule) {
            this.$confirm({
                message: `¿Desea eliminar este horario?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.schedules
                            .delete(schedule.id)
                            .then(() => {
                                const { section_id: section, course_id: course } = this.courseSection
                                this.$repository.schedules.index({ course, section, limit: 0, with: 'classroom,specialization' })
                                    .then(({ data }) => {
                                        this.schedules = data
                                    })
                                    ._loading(() => this.loadings.schedules = !this.loadings.schedules)
                            })
                            ._loading(() => this.deleting.toggle(schedule.id))
                    }
                }
            })
        },
        handleUpdateSchedule(type) {

            if (type === 'classhour') {
                const schedule = this.schedules.find($0 => $0.class_hour_id === this.isChangingClasshour)

                if (schedule) {
                    this.updateSchedule(schedule.id, { classhour_id: this.isChangingClasshour }, 'classhourUpdate')
                }
            }

            if (type === 'classroom') {
                const schedule = this.schedules.find($0 => $0.classroom_id === this.isChangingClassroom)

                if (schedule) {
                    this.updateSchedule(schedule.id, { classroom_id: this.isChangingClassroom }, 'classroomUpdate')
                }
            }

            if (type === 'day') {
                this.updateSchedule(this.isChangingDay.id, { day: this.bulk.day }, 'dayUpdate')
            }
        },
        updateSchedule(segment) {
            const { schedule } = this.selected.item
            const { day, classroom_id, class_hour_id } = schedule
            const body = { day, classroom_id, class_hour_id }
            this.$repository.schedules
                .update(schedule?.id, {...body, ...segment})
                .then(() => {
                    this.mounted()
                    // const foundIndex = this.schedules.findIndex($0 => $0.id === data.id)
                    // if (foundIndex >= 0) {
                    //     this.schedules.splice(foundIndex, 1, { ...schedule, ...data })
                    // }

                    this.cancel()
                })
        },
        handleStoreSchedule() {
            this.storeSchedule({ ...this.bulk, course_section_id: this.courseSection.id })
                .then(() => {
                    this.bulk = base_bulk
                    const { section_id: section, course_id: course } = this.courseSection
                    this.isCreating = false
                    this.$repository.schedules.index({ course, section, limit: 0, with: 'classroom,specialization' })
                        .then(({ data }) => {
                            this.schedules = data
                        })
                        ._loading(() => this.loadings.schedules = !this.loadings.schedules)
                })
        },
        clearChanges() {
            this.isChangingClasshour = null
            this.isChangingClassroom = null
        },
        ...mapActions({
            fetchClassroomSchedule: 'classrooms/schedules',
            storeSchedule: 'schedules/bulkStore',
            fetchSectionSchedule: 'sections/schedule'
        }),
        isClasshourUnavailable(day, classhour_id) {
            return this.findClasshourByDay(day, classhour_id)?.class_hours_ids?.includes(classhour_id)
        },
        findClasshourByDay(day) {
            return this.unavailableDays.find(it => it.day === day)
        },
        isDaySelectedForClassHour(index, hour) {
            return this.bulk.schedules[index].class_hours_ids.includes(hour)
        },
        getClassroomWithSpecializtion(hour, day) {
            const found = this.schedules.find($0 => $0.class_hour_id === hour.value && $0.day === day.value)

            if (found) {
                return {
                    schedule: found,
                    classroom: {
                        code: found.classroom.code,
                        id: found.classroom.id,
                        description: found.classroom.description
                    },
                    specialization: {
                        text_color: found.specialization.text_color,
                        background: found.specialization.color
                    }
                }
            }

            return {
                classroom: null,
                specialization: {
                    text_color: undefined,
                    background: undefined
                }
            }
        },
        mounted() {
            const { period_id: period, section_id: section, course_id: course } = this.courseSection
            Promise.all([
                this.$repository.classrooms.index({ limit: 0 }),
                this.$repository.classhours.index({ limit: 0, period, sort_by: 'start_hour' }),
                this.$repository.schedules.index({ course, section, period, limit: 0, with: 'classroom,specialization' })
            ])
            .then(([{ data: classrooms }, { data: classhours }, { data: schedules }]) => {
                this.classhours = classhours
                this.classrooms = classrooms
                this.schedules = schedules
            })
            ._loading(() => this.loadings.general = !this.loadings.general)
        },
        mappedSectionSchedules(hour, day) {
          let found = this.sectionSchedules.find(it => it.class_hour_id === hour.value && it.day === day.value)
          if (found) {
            if (found.course_section) {
              return {
                value: found.course_section.course.title,
                background: found.course_section.specialization.color,
                color: found.course_section.specialization.text_color,
                specialization: found.course_section.specialization.title,
                semester: found.course_section.semester.number,
                classroom: found.classroom.code,
                section: found.course_section.section.letter_code,
              }
            }
          }
          // } else {
          //   console.log('not found')
          // }

          return {}
        },
    },
    computed: {
        filteredMappedClassHours() {

            return this.mappedClassHours.filter($0 => {
                const schedule = this.selected.item?.schedule
                if (schedule) {
                    return !this.isClasshourUnavailable(schedule.day, $0.value) && this.selected.item?.schedule.class_hour_id !== $0.value
                }

                return false
            })
        },
        ...mapState({
            teachers: ({ teachers }) => teachers.all.map($0 => ({ label: `${$0.first_name} ${$0.last_name}`, value: $0.id })),
            takenClasshoursStored: state => state.classrooms.schedules,
            loadingClassrooms: state => state.classrooms.loading,
            loadingClasshours: ({ classhours }) => classhours.loading,
            sectionSchedules: state => state.sections.schedules,
            period: state => state.periods.period
        }),
        changingText() {
            if (this.isChangingClasshour) {
                return 'Seleccionar hora'
            }

            if (this.isChangingClassroom) {
                return 'Seleccionar aula'
            }

            return ''
        },
        unavailableDays() {
            let schedules = [];
            this.takenClasshours.forEach(it => {
                let foundIndex = schedules.findIndex(el => el.day === it.day);

                if (foundIndex < 0) {
                    schedules.push({
                        class_hours_ids: [it.class_hour_id],
                        day: it.day,
                        extra: [it]
                    })
                } else {
                    let hasClasshour = schedules[foundIndex].class_hours_ids.includes(it.class_hour_id)

                    if (!hasClasshour) {
                        schedules[foundIndex].class_hours_ids.push(it.class_hour_id)
                        schedules[foundIndex].extra.push(it)
                    }
                }
            })

            // this.schedules.forEach(it => {
            //     let foundIndex = schedules.findIndex(el => el.day == it.day);

            //     if (foundIndex < 0) {
            //         schedules.push({
            //             day: it.day,
            //             extra: it,
            //             class_hours_ids: [it.class_hour_id]
            //         })
            //     } else {
            //         let hasClasshour = schedules[foundIndex].class_hours_ids.includes(it.class_hour_id)

            //         if (!hasClasshour) {
            //             schedules[foundIndex].class_hours_ids.push(it.class_hour_id)
            //         }
            //     }
            // })
            return schedules
        },
        mappedClassrooms() {
            return this.classrooms.map($0 => ({ label: $0.description, value: $0.id }))
        },
        mappedClassHours() {
            return this.classhours.map(it => {
                const start_hour = this.$options.filters.timePadding(it.start_hour)
                const start_minute = this.$options.filters.timePadding(it.start_minute)
                const end_hour = this.$options.filters.timePadding(it.end_hour)
                const end_minute = this.$options.filters.timePadding(it.end_minute)
                return {
                    label: `${hourTransform(start_hour, start_minute)} - ${hourTransform(end_hour, end_minute)}`,
                    // label: `${start_hour}:${start_minute} - ${end_hour}:${end_minute}`,
                    value: it.id
                }
            })
        },
        days() {
            return [
                { label: 'Lunes', value: 1 },
                { label: 'Martes', value: 2 },
                { label: 'Miércoles', value: 3 },
                { label: 'Jueves', value: 4 },
                { label: 'Viernes', value: 5 }
            ]
        },
    },
    watch: {
        courseSection(val) {
            if (val) {
                this.mounted()
            }
        },
        'bulk.classroom_id'(id) {
            if (id) {
                this.fetchClassroomSchedule({
                    id,
                    filters: {
                        limit: 0,
                        period: this.courseSection.period_id,
                        with: 'courseSection.course'
                    }
                })
                .then(schedules => {
                    this.takenClasshours = [ ...this.sectionSchedules, ...schedules ]
                    // console.log(schedules)
                    // console.log(this.takenClasshours)
                    // console.log(schedules)
                    // console.log(this.sectionSchedules.data)
                })
                ._loading(() => this.loadings.schedules = !this.loadings.schedules)

                this.bulk.schedules = [
                    { "day": 1, "class_hours_ids": [] },
                    { "day": 2, "class_hours_ids": [] },
                    { "day": 3, "class_hours_ids": [] },
                    { "day": 4, "class_hours_ids": [] },
                    { "day": 5, "class_hours_ids": [] },
                ]
            }

        }
    },
    mounted() {
        this.mounted()
    }
}
</script>

<style scoped>

</style>
